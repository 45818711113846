<template>
  <dashboard-layout>
    <div class="flex items-center justify-center">
      <div
        class="w-11/12 md:w-8/12 xl:w-7/12 mx-auto md:mx-0 rounded-lg bg-white bg-opacity-80 p-3 mb-5 text-justify"
      >
        <div v-html="term"></div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';

export default {
  name: 'SingleTerm',

  title() {
    return `${process.env.VUE_APP_NAME} | Termo de conformidade com a LGPD`;
  },

  components: {
    DashboardLayout
  },

  data() {
    return {
      term: ''
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    fetchTerm() {
      axios.get('/api/termos/vigente').then(({ data }) => {
        this.id = data.data.id;
        this.term = data.data.term;
        this.obs = data.data.obs;
      });
    }
  },

  created() {
    this.fetchTerm();
  }
};
</script>
